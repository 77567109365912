import React, { FC } from 'react';

import { ILandingVideoComponentProps } from 'components/LandingVideo/models';
import 'components/LandingVideo/LandingVideo.scss';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { DangerouslySetInnerHtml } from 'layout';

const LandingVideo: FC<ILandingVideoComponentProps> = ({ properties }) => {
  const { isMobile, isTablet, isDesktop, isExtraLargeDesktop } = useScreenRecognition();
  let backgroundImage;
  if (properties.backgroundImage?.variants) {
    if (isMobile)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Mobile'
      )?.url;
    if (isTablet)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Tablet'
      )?.url;
    if (isExtraLargeDesktop)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Desktop'
      )?.url;
  }

  let videoDimensions = { width: 335, height: 188 };
  if (isMobile) videoDimensions = { width: 335, height: 188 };
  if (isTablet) videoDimensions = { width: 549, height: 308 };
  if (isDesktop) videoDimensions = { width: 916, height: 515 };

  return (
    <div
      style={{
        background: properties.backgroundStyle,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
      }}
      className="landing-page-block landing-page-block--video"
    >
      <div className="landing-page-block__container">
        <>
          <DangerouslySetInnerHtml
            className="landing-page-block__container"
            html={properties.content}
          />
        </>
        <iframe
          width={videoDimensions.width}
          height={videoDimensions.height}
          src={`https://www.youtube.com/embed/${properties.youtubeVideoId}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default LandingVideo;
