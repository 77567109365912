import React, { FC, useEffect } from 'react';

import { ILandingTiktokFeedComponentProps } from 'components/LandingTiktokFeed/models';
import 'components/LandingTiktokFeed/LandingTiktokFeed.scss';
import { DangerouslySetInnerHtml } from 'layout';
import useScreenRecognition from 'hooks/useScreenRecognition';

const LandingTiktokFeed: FC<ILandingTiktokFeedComponentProps> = ({ properties }) => {
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();

  let backgroundImage;
  if (properties.backgroundImage?.variants) {
    if (isMobile)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Mobile'
      )?.url;
    if (isTablet)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Tablet'
      )?.url;
    if (isDesktop)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Desktop'
      )?.url;
  }
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        background: properties.backgroundStyle,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
      }}
      className="landing-page-block landing-page-block--tiktok-feed"
    >
      <DangerouslySetInnerHtml
        className="landing-page-block__container"
        html={properties.content}
      />
    </div>
  );
};

export default LandingTiktokFeed;
