import React, { FC } from 'react';

import { ILandingTextBlockComponentProps } from 'components/LandingTextBlock/models';
import 'components/LandingTextBlock/LandingTextBlock.scss';
import { DangerouslySetInnerHtml } from 'layout';
import useScreenRecognition from 'hooks/useScreenRecognition';
import classNames from 'classnames';

const LandingTextBlock: FC<ILandingTextBlockComponentProps> = ({ properties }) => {
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();
  let heroImage;
  if (properties.heroImage?.variants) {
    if (isMobile)
      heroImage = properties.heroImage?.variants?.find((variant) => variant.alias === 'Mobile')
        ?.url;
    if (isTablet)
      heroImage = properties.heroImage?.variants?.find((variant) => variant.alias === 'Tablet')
        ?.url;
    if (isDesktop)
      heroImage = properties.heroImage?.variants?.find((variant) => variant.alias === 'Desktop')
        ?.url;
  }

  let backgroundImage;
  if (properties.backgroundImage?.variants) {
    if (isMobile)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Mobile'
      )?.url;
    if (isTablet)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Tablet'
      )?.url;
    if (isDesktop)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Desktop'
      )?.url;
  }

  return (
    <div
      style={{
        background: properties.backgroundStyle,
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
      }}
      className={classNames(
        'landing-page-block',
        { 'landing-page-block--reverse-hero': properties.reverseHero },
        { 'landing-page-block--with-hero': heroImage },
        { 'landing-page-block--no-padding': properties.noPadding }
      )}
    >
      {heroImage ? (
        <div
          role="img"
          aria-label={properties.heroImage?.altText}
          className="landing-page-block__hero"
          style={{ backgroundImage: `url(${heroImage})` }}
        />
      ) : null}
      <div
        className={classNames(
          'landing-page-block__container',
          { 'landing-page-block__container--with-hero': heroImage },
          { 'landing-page-block__container--with-hero--reverse': properties.reverseHero }
        )}
      >
        <DangerouslySetInnerHtml html={properties.content} />
      </div>
    </div>
  );
};

export default LandingTextBlock;
