import React, { FC } from 'react';

import './LandingPage.scss';
import { Layout } from 'layout';
import { graphql } from 'gatsby';
import RelatedProducts from 'components/RelatedProducts';
import LandingVideoBanner from 'components/LandingVideoBanner';
import LandingTextBlock from 'components/LandingTextBlock';
import LandingTiktokFeed from 'components/LandingTiktokFeed';
import LandingVideo from 'components/LandingVideo';
import { ILandingPageComponentProps } from './models';

const LandingPage: FC<ILandingPageComponentProps> = ({
  data: {
    umbracoLanding: {
      defaultCompositions,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      blocks,
      relatedProductsCardCtaButton,
      relatedProductsCtaButtonShow,
      relatedProductsTitle,
      relatedProductsCardCTAButtonAriaLabel,
      relatedProductsCtaButton,
    },
    relatedProducts,
  },
}) => {
  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      hideHeader
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoExternalHreflangs,
      }}
    >
      <div className="landing-page">
        {blocks.map((block, index) => {
          switch (block.structure) {
            case 'Video Banner':
              return <LandingVideoBanner properties={block.properties} key={index} />;
            case 'Landing Text Block':
              return <LandingTextBlock properties={block.properties} key={index} />;
            case 'Landing TikTok Feed':
              return <LandingTiktokFeed properties={block.properties} key={index} />;
            case 'Landing Video':
              return <LandingVideo properties={block.properties} key={index} />;
            default:
              return null;
          }
        })}

        {/* Related products */}
        <RelatedProducts
          mainProduct={{
            nodes: [],
          }}
          {...{
            relatedProducts,
            relatedProductsCardCtaButton,
            relatedProductsCtaButton,
            relatedProductsTitle,
            relatedProductsCardCTAButtonAriaLabel,
            relatedProductsCtaButtonShow,
          }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($url: String, $lang: String, $relatedProductsLinks: [String]) {
    umbracoLanding(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      blocks {
        structure
        properties {
          videoSquare {
            url
          }
          videoNarrow {
            url
          }
          videoWide {
            url
          }
          backgroundImage {
            variants {
              url
              alias
            }
          }
          heroImage {
            altText
            variants {
              url
              alias
            }
          }
          content
          backgroundStyle
          youtubeVideoId
          reverseHero
          noPadding
        }
      }
      relatedProductsCardCtaButton
      relatedProductsCtaButtonShow
      relatedProductsTitle
      relatedProductsCardCTAButtonAriaLabel
      relatedProductsCtaButton {
        url
      }
      relatedProductsLinks
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $relatedProductsLinks } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
  }
`;

export default LandingPage;
